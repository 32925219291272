import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";

export default function AllGames() {
  return (
    <Layout>
      <Helmet>
        <title>Games/Toys | Nathan Pasko</title>
        <meta
          name="description"
          content="Index of all games and toys by Nathan Pasko including non-commercial mystery and horror, retro reimaginings, labor sims, virtual pets, and other oddities."
        />
      </Helmet>
      <div className="project-page">
        <h1>Games/Toys</h1>
        <nav>
          <ul>
            <li>
              <Link to="/projects/g-pet">G. Pet</Link>
            </li>
            <li>
              <Link to="/projects/petfinderx">PetFinderX</Link>
            </li>
            <li>
              <Link to="/projects/anemone">Anemone</Link>
            </li>
            <li>
              <Link to="/projects/nasa-pogs">NASA Pogs</Link>
            </li>
            <li>
              <Link to="/projects/cavalcade">Cavalcade</Link>
            </li>
            <li>
              <Link to="/projects/giants-chalice">Giant's Chalice</Link>
            </li>
            <li>
              <Link to="/projects/s-pet">S. Pet</Link>
            </li>
            <li>
              <Link to="/projects/trade-around">Trade Around</Link>
            </li>
            <li>
              <Link to="/projects/supreme-defender">Supreme Defender Franchise</Link>
            </li>
          </ul>
        </nav>
      </div>
    </Layout>
  );
}
